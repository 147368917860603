import 'moment/locale/ru';
import Network from '../services/Network';
import { handle } from "../common/ErrorHandler";
import moment, { Moment } from "moment-timezone";
import 'moment/locale/ru';
import { setHashParam, getHashParam } from '../pages/Schedule/js/schedule-scripts';
import { dateFormats, localStorageKeys, UserRoles } from '../common/Constants';
import URLPaths from '../common/URLPaths';
import { ScheduleColumn } from "../models/ScheduleColumn";
import { LessonGrid } from "../models/LessonGrid";
import { Professor } from "../models/Professor";
import { Group } from "../models/Group";
import { Audience } from "../models/Audience";
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getIdFromPathname, getTypeFromPathname } from '../pages/Schedule/components/CombinedScheduleDrawer/js/drawer-scripts';
import { combinedScheduleDrawerSlice } from './combinedScheduleDrawerSlice';
import { RootState } from '../store/store';
import { getMatchParams } from '../js/getMatchParams';
import { AuthProfile } from '../models/AuthProfile';

interface ScheduleState {
    startDate: Moment,
    endDate: Moment,
    isLoadingSchedule: boolean,
    selectedLesson: LessonGrid | null,
    isDetailsModalShown: boolean,
    isBookingModalShown: boolean,
    schedule: ScheduleColumn[],
    canBook: boolean,
    isCombinedScheduleActive: boolean,
    numOfActiveSchedules: number,
    shouldToRedirect: boolean,
    shouldToRedirectCombined: boolean,
    paramsForURL: URLSearchParams | null
}

const initialState: ScheduleState = {
    startDate: moment().startOf('week'),
    endDate: moment().endOf("week").subtract(1, 'day'),
    isLoadingSchedule: false,
    selectedLesson: null,
    isDetailsModalShown: false,
    isBookingModalShown: false,
    schedule: [],
    canBook: false,
    isCombinedScheduleActive: false,
    numOfActiveSchedules: 0,
    shouldToRedirect: false,
    shouldToRedirectCombined: false,
    paramsForURL: null
}

export const scheduleSlice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        clearSchedule: () => initialState,
        setURLSearhParams(state, action: PayloadAction<URLSearchParams>) {
            state.paramsForURL = action.payload;
        },
        setShouldToRedirect(state, action: PayloadAction<boolean>) {
            state.shouldToRedirect = action.payload;
        },
        setShouldToRedirectCombined(state, action: PayloadAction<boolean>) {
            state.shouldToRedirectCombined = action.payload;
        },
        setSelectedLesson(state, action: PayloadAction<LessonGrid>) {
            state.selectedLesson = action.payload;
        },
        setIsDetailsModalShown(state, action: PayloadAction<boolean>) {
            state.isDetailsModalShown = action.payload;
        },
        setIsBookingModalShown(state, action: PayloadAction<boolean>) {
            state.isBookingModalShown = action.payload;
        },
        setIsCombinedScheduleActive(state, action: PayloadAction<{ isCombinedScheduleActive: boolean, numOfActiveSchedules: number }>) {
            state.isCombinedScheduleActive = action.payload.isCombinedScheduleActive;
            state.numOfActiveSchedules = action.payload.numOfActiveSchedules;
        },
        showNextWeek(state) {
            let newStartDate = state.startDate.add("7", "day");
            let newEndDate = state.endDate.add("7", "day");
            setHashParam('startDate', newStartDate.toISOString());
            setHashParam('endDate', newEndDate.toISOString());

            state.startDate = newStartDate;
            state.endDate = newEndDate;
        },
        showPrevWeek(state) {
            let newStartDate = state.startDate.subtract("7", "day");
            let newEndDate = state.endDate.subtract("7", "day");
            setHashParam('startDate', newStartDate.toISOString());
            setHashParam('endDate', newEndDate.toISOString());

            state.startDate = newStartDate;
            state.endDate = newEndDate;
        },
        setStartAndEndDates(state, action: PayloadAction<{ startDate: Moment, endDate: Moment }>) {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setCanBook(state, action: PayloadAction<boolean>) {
            state.canBook = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSchedule.pending, (state) => {
            state.isLoadingSchedule = true;
        })
        builder.addCase(fetchSchedule.fulfilled, (state, action) => {
            state.isLoadingSchedule = false;
            state.isCombinedScheduleActive = false;
            state.schedule = action.payload;
        })
        builder.addCase(fetchSchedule.rejected, (state, action) => {
            state.isLoadingSchedule = false;
            if (action.error.code !== "ERR_CANCELED") {
                handle();
            }
        })

        builder.addCase(fetchScheduleCombined.pending, (state) => {
            state.isLoadingSchedule = true;
        })
        builder.addCase(fetchScheduleCombined.fulfilled, (state, action) => {
            state.isLoadingSchedule = false;
            state.schedule = action.payload.responseData;
            state.isCombinedScheduleActive = true;
            state.numOfActiveSchedules = action.payload.numOfChoosen;
        })
        builder.addCase(fetchScheduleCombined.rejected, (state, action) => {
            state.isLoadingSchedule = false;
            if (action.error.code !== "ERR_CANCELED") {
                handle();
            }
        })
    }
})

export const fetchSchedule = createAsyncThunk(
    "schedule/fetchSchedule",
    async function (_: void, thunkAPI) {
        const state = thunkAPI.getState() as RootState;
        const scheduleState = state.scheduleReducer;
        const matchParams = getMatchParams();
        const response = await Network.request(URLPaths.schedule(matchParams.type),
            "GET", {
            id: matchParams.scheduleId,
            dateFrom: scheduleState.startDate.format(dateFormats.YEAR_MONTH_DAY),
            dateTo: scheduleState.endDate.format(dateFormats.YEAR_MONTH_DAY)
        })

        if (response.status === 200) {
            thunkAPI.dispatch(combinedScheduleDrawerSlice.actions.saveActiveArrays())
            thunkAPI.dispatch(scheduleSlice.actions.setShouldToRedirect(true))
        }

        return response.data;
    }
);

export const fetchScheduleCombined = createAsyncThunk(
    "schedule/fetchScheduleCombined",
    async function (_: void, thunkAPI) {

        let params = new URLSearchParams();
        let paramsForURL = new URLSearchParams();

        const state = thunkAPI.getState() as RootState;
        const combinedScheduleState = state.combinedScheduleDrawerSlice;

        params.append(getTypeFromPathname(), getIdFromPathname());
        combinedScheduleState.selectedProfessors.forEach((element: Professor) => {
            params.append('professorIds', element.id);
            paramsForURL.append('professors', `${element.id}!${element.fullName}`);
        })

        combinedScheduleState.selectedGroups.forEach((element: Group) => {
            params.append('groupIds', element.id);
            paramsForURL.append('groups', `${element.id}!${element.name}`);
        })

        combinedScheduleState.selectedAuditories.forEach((element: Audience) => {
            params.append('audienceIds', element.id);
            paramsForURL.append('audiences', `${element.id}!${element.name}`);
        })

        const scheduleState = state.scheduleReducer;
        params.append('dateFrom', scheduleState.startDate.format(dateFormats.YEAR_MONTH_DAY));
        params.append('dateTo', scheduleState.endDate.format(dateFormats.YEAR_MONTH_DAY));

        const response = await Network.request((URLPaths.scheduleCombined), "GET", params)

        if (response.status === 200) {
            thunkAPI.dispatch(combinedScheduleDrawerSlice.actions.saveActiveArrays())
            thunkAPI.dispatch(scheduleSlice.actions.setURLSearhParams(paramsForURL))
            thunkAPI.dispatch(scheduleSlice.actions.setShouldToRedirectCombined(true))
        }

        return { responseData: response.data, numOfChoosen: combinedScheduleState.numOfChoosen };
    }
);

export const fetchScheduleFromGetParam = createAsyncThunk(
    "schedule/fetchScheduleFromGetParam",
    async function (urlParams: URLSearchParams, thunkAPI) {

        const { setSelectedAuditories, setSelectedGroups, setSelectedProfessors } = combinedScheduleDrawerSlice.actions;

        urlParams.getAll('groups').forEach((val: string) => {
            const idAndName = val.split('!');
            const group: Group = { id: idAndName[0], name: idAndName[1] }
            thunkAPI.dispatch(setSelectedGroups(group))
        })
        urlParams.getAll('professors').forEach((val: string) => {
            const idAndName = val.split('!');
            const professor: Professor = { id: idAndName[0], fullName: idAndName[1] }
            thunkAPI.dispatch(setSelectedProfessors(professor))
        })
        urlParams.getAll('audiences').forEach((val: string) => {
            const idAndName = val.split('!');
            const auditory: Audience = { id: idAndName[0], name: idAndName[1] }
            thunkAPI.dispatch(setSelectedAuditories(auditory))
        })

        thunkAPI.dispatch(fetchScheduleCombined())
    }
);

export const setScheduleState = createAsyncThunk(
    "schedule/setScheduleState",
    async function (_: void, thunkAPI) {

        let profile : AuthProfile = JSON.parse(localStorage.getItem(localStorageKeys.USER_PROFILE) as string);
        const { setStartAndEndDates, setCanBook } = scheduleSlice.actions;
        if (profile && profile.roles) {
            thunkAPI.dispatch(setCanBook(profile.roles.includes(UserRoles.STAFF) || profile.roles.includes(UserRoles.STAFF)))
        }

        let startDate: any = getHashParam("startDate");
        let endDate: any = getHashParam("endDate");

        if (startDate && endDate) {
            thunkAPI.dispatch(setStartAndEndDates({ startDate: moment(startDate), endDate: moment(endDate) }))
        }

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.has('numOfChoosen')) {
            thunkAPI.dispatch(fetchScheduleFromGetParam(urlParams))
        }
        else {
            thunkAPI.dispatch(fetchSchedule())
        }
    }
);

export const closeNetwork = createAsyncThunk(
    "schedule/closeNetwork",
    async function (_: void, thunkAPI) {
        Network.cancel()

        thunkAPI.dispatch(scheduleSlice.actions.clearSchedule());
        thunkAPI.dispatch(combinedScheduleDrawerSlice.actions.clearDrawerSlice());
        thunkAPI.dispatch(scheduleSlice.actions.setIsCombinedScheduleActive({ isCombinedScheduleActive: false, numOfActiveSchedules: 0 }))
    }
)

export default scheduleSlice.reducer;