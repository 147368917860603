import { Button, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import '../css/checkBoxGroup.scss'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { combinedScheduleDrawerSlice, unsetAndFetchCombinedSchedule } from '../../../../../reducers/combinedScheduleDrawerSlice';
import { Professor } from "../../../../../models/Professor";
import { Group } from "../../../../../models/Group";
import { Audience } from "../../../../../models/Audience";

function ChoosenTab() {

    const { selectedAuditories, selectedGroups, selectedProfessors } = useAppSelector(state => state.combinedScheduleDrawerSlice);
    const { unsetSelectedAuditories, unsetSelectedGroups, unsetSelectedProfessors } = combinedScheduleDrawerSlice.actions;

    return (
        <>
            <Typography.Title level={5}>Группы</Typography.Title>
            {GetChoosen(selectedGroups, unsetSelectedGroups)}

            <Typography.Title level={5}>Преподаватели</Typography.Title>
            {GetChoosen(selectedProfessors, unsetSelectedProfessors)}


            <Typography.Title level={5}>Аудитории</Typography.Title>
            {GetChoosen(selectedAuditories, unsetSelectedAuditories)}
        </>
    )
}

function GetChoosen(array: Group[] | Audience[] | Professor[], unsetThunkCreator: Function) {

    const dispatch = useAppDispatch();

    function unset(choosen: any) {
        dispatch(unsetAndFetchCombinedSchedule({ choosen, unsetThunkCreator }))
    }

    return (
        array.map(choosen => {

            return (
                <div
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px' }}
                    key={choosen.id}
                >
                    <div>{'fullName' in choosen ? choosen.fullName : choosen.name}</div>
                    <Button
                        icon={<CloseOutlined />}
                        onClick={() => unset(choosen)}
                        type='text'
                        shape='circle' danger
                    />
                </div>
            )
        })
    )
}

export default ChoosenTab;