import { ScheduleColumn } from "../../../models/ScheduleColumn";

export function getLessonTimes(schedule: ScheduleColumn[]) {
    let lessonTimes = schedule[0].lessons.map(lesson => {
        return {
            starts: lesson.starts,
            ends: lesson.ends
        };
    }).filter((value, index, lessonTimes) =>
        index === lessonTimes.findIndex(lessonTime => (
            lessonTime.starts === value.starts && lessonTime.ends === value.ends
        ))
    );

    return lessonTimes;
}