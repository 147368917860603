import { getMatchParams } from "../js/getMatchParams"
import { Professor } from "../models/Professor"
import { Group } from "../models/Group"
import { Audience } from "../models/Audience"
import { getHashParam } from "../pages/Schedule/js/schedule-scripts"

enum APIversion {
    v1 = '/v1',
    v2 = '/v2'
}

const URLPaths = {
    loginTsuAccount: APIversion.v1 + "/auth/login/tsu-account",
    bookings: APIversion.v1 + "/bookings",
    buildings: APIversion.v1 + "/buildings",
    freeAuditories: APIversion.v1 + "/free-auditories",
    faculties: APIversion.v1 + "/faculties",
    professors: APIversion.v1 + "/professors",
    userProfile: APIversion.v1 + "/user/profile",
    notificationSubscribe: APIversion.v1 + "/user/notifications/subscribe",
    notificationUnsubscribe: APIversion.v1 + "/user/notifications/unsubscribe",
    refreshToken: APIversion.v1 + "/auth/refresh",
    scheduleCombined: APIversion.v2 + "/schedule/week",
    loginApplicationId: 'https://accounts.tsu.ru/Account/Login2/?applicationId=1043',

    cancelBooking: (id: string) => { return `${APIversion.v1}/bookings/${id}/cancel` },
    audiences: (selectedBuilding: string) => { return `${APIversion.v1}/buildings/${selectedBuilding}/audiences` },
    groups: (selectedFaculty: string) => { return `${APIversion.v1}/faculties/${selectedFaculty}/groups` },
    schedule: (matchParamsType: string) => { return `${APIversion.v1}/schedule/${matchParamsType}` },
    bookingFields: (audienceId: string) => { return `${APIversion.v1}/audiences/${audienceId}/booking-fields` }
}


export const schedulePaths = {
    getInitPathAudience: (selectedAuditoryId: string, auditories: Audience[]) => {
        return `/schedule/audience/${selectedAuditoryId}?name=${auditories.find((auditory) => auditory.id === selectedAuditoryId)?.name}`
    },
    getInitPathGroup: (selectedGroupId: string, groups: Group[]) => {
        return `/schedule/group/${selectedGroupId}?name=${groups.find(group => group.id === selectedGroupId)?.name}`
    },
    getInitPathProfessor: (selectedProfessorId: string, professors: Professor[]) => {
        return `/schedule/professor/${selectedProfessorId}?name=${professors.find(professor => professor.id === selectedProfessorId)?.fullName}`
    },

    getSchedulePath: (paramsForURL?: URLSearchParams, numOfChoosen?: number) => {
        const matchParams = getMatchParams();
        return `/schedule/${matchParams.type}/${matchParams.scheduleId}?name=${matchParams.name}${numOfChoosen ? `&${paramsForURL!.toString()}&numOfChoosen=${numOfChoosen}` : ''}#endDate=${getHashParam('endDate')}&startDate=${getHashParam('startDate')}`
    }
}

export default URLPaths;