import { Avatar, Button, Typography } from "antd";
import { useAppSelector } from "../../../hooks/redux";
import { clearLocalStorageAndRedirectToLogin } from "../../../js/localStorageActions";
import avatarPlaceholder from '../../../images/avatar-placeholder.png';
const { Title } = Typography;

function AvatarAndExitButton() {

    const { profile } = useAppSelector(state => state.profileReducer);

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar style={{ marginRight: 16 }} src={profile.tsuAccountProfile.avatarUrl ?? avatarPlaceholder} size={88} />
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Title level={2}>
                    {`${profile.tsuAccountProfile.firstName} ${profile.tsuAccountProfile.secondName} ${profile.tsuAccountProfile.lastName}`}
                </Title>
                <Button danger onClick={() => {
                    clearLocalStorageAndRedirectToLogin();
                }}>
                    Выход
                </Button>
            </div>
        </div>
    )
}

export default AvatarAndExitButton;