export enum lessonColors {
  LECTURE = "red",
  SEMINAR = "orange",
  PRACTICE = "geekblue",
  LABORATORY = "blue",
  INDIVIDUAL = "cyan",
  OTHER = "green",
  CONTROL_POINT = "purple",
  EXAM = "purple",
  CREDIT = "purple",
  DIFFERENTIAL_CREDIT = "purple",
  CONSULTATION = "pink",
  BOOKING = "default",
}

export enum lessonTypes {
  LECTURE = "Лекция",
  SEMINAR = "Семинар",
  PRACTICE = "Практическое занятие",
  LABORATORY = "Лабораторная",
  INDIVIDUAL = "Индивидуальное занятие",
  OTHER = "Иная контактная работа (сопровождение работы в ЭУК)",
  CONTROL_POINT = "Контрольная работа",
  EXAM = "Экзамен",
  CREDIT = "Зачёт",
  DIFFERENTIAL_CREDIT = "Дифф. зачёт",
  CONSULTATION = "Консультация",
  BOOKING = "Бронь",
  EMPTY = "EMPTY",
}

export enum scheduleNames {
  group = "группы",
  professor = "преподавателя",
  audience = "аудитории",
}

export enum localStorageKeys {
  TOKEN_ACCESS = "token.access",
  TOKEN_REFRESH = "token.refresh",
  USER_PROFILE = "user.profile",
  SCHEDULE_TO_PRINT = "schedule.to.print",
}

export enum UserRoles {
  STAFF = "STAFF",
  ADMIN = "ADMIN",
}

export enum dateFormats {
  YEAR_MONTH_DAY = "YYYY-MM-DD",
  HOUR_MINUTES = "H:mm",
  DAY_MONTH_YEAR = "D MMMM YYYY",
  DAY = "dd",
  DAY_MONTH = "D MMM",
  DAY_COMMA_DAY_MONTH = "dd, D MMM",
}

const Constants = {
  apiBaseURL: process.env.REACT_APP_API_HOST + "/api/old-web",
  scheduleTimes: [
    {
      start: "08:45",
      end: "10:20",
    },
    {
      start: "10:35",
      end: "12:10",
    },
    {
      start: "12:25",
      end: "14:00",
    },
    {
      start: "14:45",
      end: "16:20",
    },
    {
      start: "16:35",
      end: "18:10",
    },
    {
      start: "18:25",
      end: "20:00",
    },
    {
      start: "20:15",
      end: "21:50",
    },
  ],
};

export default Constants;
