import { Avatar } from "antd";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import { ReactComponent as Logo } from "../logo.svg";
import { MenuOutlined } from "@ant-design/icons";
import { useAppSelector } from "../hooks/redux";
import avatarPlaceholder from '../images/avatar-placeholder.png';

function InTimeMenu() {
    const { profile, myScheduleInfo } = useAppSelector(state => state.profileReducer);


    function getMyScheduleMenu() {

        if (myScheduleInfo === null) {
            return null
        }

        if (myScheduleInfo.group !== null && myScheduleInfo.professor !== null) {

            return (
                <Menu.SubMenu key={"/mySchedule"} title="Моё расписание">
                    <Menu.Item key={"/mySheduleProfessor"}>
                        <Link reloadDocument to={myScheduleInfo.professor.link}>
                            {myScheduleInfo.professor.fullName}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={"/mySheduleGroup"}>
                        <Link reloadDocument to={myScheduleInfo.group.link}>
                            Группа {myScheduleInfo.group.name}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={"/mySheduleCombined"}>
                        <Link reloadDocument to={myScheduleInfo.combined!}>
                            Объединённое
                        </Link>
                    </Menu.Item>
                </Menu.SubMenu>
            )
        }

        if (myScheduleInfo.group !== null) {
            return (
                <Menu.Item key={"/mySheduleGroup"}>
                    <Link reloadDocument to={myScheduleInfo.group.link}>
                        Моё расписание
                    </Link>
                </Menu.Item>
            )
        }
        else if (myScheduleInfo.professor !== null) {
            return (
                <Menu.Item key={"/mySheduleProfessor"}>
                    <Link reloadDocument to={myScheduleInfo.professor.link}>
                        Моё расписание
                    </Link>
                </Menu.Item>
            )
        }
    }


    return (
        <Menu
            theme="dark"
            mode="horizontal"
            overflowedIndicator={<MenuOutlined />}
            selectedKeys={[useLocation().pathname]}
        >
            <Menu.Item className={"logo"}>
                <Link to={"/"}>
                    <Logo style={{ marginTop: 12, marginRight: 8 }} />
                    TSU.InTime
                </Link>
            </Menu.Item>
            <Menu.Item key={"/"}>
                <Link to={"/"}>
                    Главная
                </Link>
            </Menu.Item>
            <Menu.Item key={"/groups"}>
                <Link to={"/groups"}>
                    Группы
                </Link>
            </Menu.Item>
            <Menu.Item key={"/professors"}>
                <Link to={"/professors"}>
                    Преподаватели
                </Link>
            </Menu.Item>
            <Menu.Item key={"/auditories"} style={{ marginRight: "auto" }} >
                <Link to={"/auditories"}>
                    Аудитории
                </Link>
            </Menu.Item>

            {
                profile ?
                    <>
                        {
                            getMyScheduleMenu()
                        }

                        <Menu.Item key={"/profile"}>
                            <Link to={"/profile"}>
                                <Avatar style={{ marginRight: 10 }} size="small"
                                    src={profile.tsuAccountProfile.avatarUrl ?? avatarPlaceholder} />
                                {profile.tsuAccountProfile.firstName}
                            </Link>
                        </Menu.Item>
                    </>
                    :
                    <Menu.Item key={"/login"}>
                        <Link to={"/login"} >
                            Вход
                        </Link >
                    </Menu.Item>
            }
        </Menu>
    )
}

export default InTimeMenu;