import moment, { Moment } from "moment-timezone";
import queryString from "query-string";
import { localStorageKeys } from "../../../common/Constants";
import { ScheduleColumn } from "../../../models/ScheduleColumn";

function startOfAcademicYear(date: Moment) {
    let currentMonth = date.month() + 1;
    let currentAcademicYear;
    if (currentMonth < 9) {
        currentAcademicYear = date.years() - 1;
    } else {
        currentAcademicYear = date.years();
    }

    let day = 1
    let start = moment([currentAcademicYear, 8, day])
    let startDay = start.get('day') 

    while (startDay === 0  || startDay === 6|| startDay === 5) {
        start =  moment([currentAcademicYear, 8, ++day])
        startDay = start.get('day')
    }
    
    return start;
};

function firstFullAcademicWeekDate(date: Moment) {
    let academicYearStart = startOfAcademicYear(date);
    let academicYearStartWeekday = academicYearStart.isoWeekday();
    return academicYearStart.add(8 - academicYearStartWeekday, 'days');
}


export function numberOfAcademicWeek(date: Moment) {
    let firstFullWeekDate = firstFullAcademicWeekDate(date);
    let daysFromFirstFullWeek = Math.abs(firstFullWeekDate.diff(date, 'days')) - 1;
    return Math.ceil(daysFromFirstFullWeek / 7) + 1
}

export function setHashParam(key: string, value: string) {
    let parsed = queryString.parse(window.location.hash);
    parsed[key] = value;
    window.location.hash = queryString.stringify(parsed);
}

export function getHashParam(key: string) {
    let parsed = queryString.parse(window.location.hash);
    return parsed[key];
}

export function printSchedule(schedule: ScheduleColumn[]) {
    localStorage.setItem(localStorageKeys.SCHEDULE_TO_PRINT, JSON.stringify(schedule));
    window.open("/schedule/print", "_blank");
}