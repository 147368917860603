import React, {useEffect, useState} from 'react';
import {Col, Row} from "antd";

function PrivacyPolicy() {
    let [htmlFileString, setHtmlFileString] = useState<string>();

    async function fetchHtml() {
        setHtmlFileString(await (await fetch("privacy.html")).text());
    }
    
    useEffect(() => {
        fetchHtml();
    }, []);
    
    return (
        <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={16}>
                <div className={"groups_content-container"}>
                    { htmlFileString && <div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div> }
                </div>
            </Col>
        </Row>
    );
}

export default PrivacyPolicy;