import React, { useEffect } from "react";
import moment from "moment-timezone";
import "./schedule.scss";
import { dateFormats, lessonTypes, localStorageKeys } from "../../common/Constants";
import { LessonTime } from "../../models/LessonTime";
import { ScheduleColumn } from "../../models/ScheduleColumn";

export function PrintSchedule() {

    let schedule: ScheduleColumn[] = JSON.parse(localStorage.getItem(localStorageKeys.SCHEDULE_TO_PRINT) as string); //Данные для печати тркбуется получасть из localStorage, т.к. State обнуляется в новой вкладке

    useEffect(() => {
        window.print();
    }, [])

    let lessonTimes: LessonTime[] = [];
    if (schedule[0] !== null && schedule[0] !== undefined) {
        lessonTimes = schedule[0].lessons.map(lesson => {
            return {
                starts: lesson.starts,
                ends: lesson.ends
            };
        }).filter((value: LessonTime, index: number, lessonTimes: LessonTime[]) =>
            index === lessonTimes.findIndex(time => (
                time.starts === value.starts && time.ends === value.ends
            ))
        );
    }

    return (
        <div>
            <table cellPadding={"8"} className={"print-table"}>
                <tr>
                    <td />
                    {
                        schedule.map(scheduleColumn => {
                            return <td>
                                <div className={"schedule_date-title"}>
                                    <div>
                                        {moment(scheduleColumn.date).format(dateFormats.DAY)}
                                    </div>
                                    <div>
                                        {moment(scheduleColumn.date).format(dateFormats.DAY_MONTH)}
                                    </div>
                                </div>
                            </td>
                        })
                    }
                </tr>
                {
                    lessonTimes.map(time => {
                        return <tr>
                            <td className={"schedule-times"}>
                                <div>{moment.unix(time.starts).format(dateFormats.HOUR_MINUTES)}</div>
                                <div>{moment.unix(time.ends).format(dateFormats.HOUR_MINUTES)}</div>
                            </td>
                            {
                                schedule.map(scheduleColumn => {
                                    return <td>
                                        {
                                            scheduleColumn.lessons.filter((lesson) => (lesson.starts === time.starts && lesson.type !== lessonTypes.EMPTY)).map((lesson) => {
                                                return <div className={"table-lesson"}>
                                                    <div className={"lesson-title"}>{lesson.title}</div>
                                                    <div
                                                        className={"lesson-professor"}>{lesson.professor.fullName}</div>
                                                    <div className={"lesson-auditory"}>{lesson.audience.name}</div>
                                                    <div
                                                        className={"lesson-group"}>{lesson.groups.map((group) => group.name).join(", ")}</div>
                                                </div>
                                            })
                                        }

                                    </td>
                                })
                            }
                        </tr>
                    })
                }
            </table>
        </div>
    );
}