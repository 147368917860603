import { DefaultOptionType } from "antd/lib/select";
import { Professor } from "../models/Professor";

export function filterOptionForSelector(input: string, option: DefaultOptionType | undefined): boolean {
    return (option!.children as unknown as string).toLowerCase().includes(input.trim().toLowerCase())
}

export function getFilteredArray<T extends { name: string }>(array: T[], input: string): T[] {
    return array.filter(element => element.name.toLowerCase().includes(input.trim().toLowerCase()) === true)
}

export function getFilteredArrayOfProfessors(array: Professor[], input: string): Professor[] {
    return array.filter(element => element.fullName.toLowerCase().includes(input.trim().toLowerCase()) === true)
}