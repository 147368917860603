import React, { useEffect, useState } from "react";
import { Button, Spin, Tabs, Input, InputNumber, Typography } from "antd";
import moment from "moment-timezone";
import '../bookAuditory.scss';
import { getLessonTimes } from "../js/bookAuditory-scripts";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { bookAuditorySlice, fetchAdditionalFields, fetchBookAuditory } from "../../../reducers/bookAuditorySlice";
import { scheduleSlice } from "../../../reducers/scheduleSlice";
import { LessonTime } from "../../../models/LessonTime";
import { ParamBookAuditoryDTO } from "../../../models/ParamBookAuditoryDTO";
import { ScheduleColumn } from "../../../models/ScheduleColumn";

function isValidCheck(value: string | number | null) {
    if (value === null) return false
    return value === "" || !value ? false : true
}

export interface BookAuditoryProps {
    auditoryID: string,
    schedule: ScheduleColumn[]
}

function BookAuditory(props: BookAuditoryProps) {

    const { eventTitle, participantCount, selectedTimes, selectedDate,
        isLoading, additionalFields, additionalFieldsValues } = useAppSelector(state => state.bookAuditorySlice);
    const { toggleTime, tabIsChanged, setSelectedDate,
        setEventTitle, setParticipantCount, setAdditionalFieldsValues } = bookAuditorySlice.actions;
    const dispatch = useAppDispatch();


    const [isEventTitleValid, setIsEventTitleValid] = useState(false);
    const [isPrticipantCountValid, setIsPrticipantCountValid] = useState(false);
    const [isSelectedTimesValid, setIsSelectedTimesValid] = useState(false);
    const [isAdditionalFieldsValid, setIsAdditionalFieldsValid] = useState(false);

    useEffect(() => {
        dispatch(fetchAdditionalFields(props.auditoryID));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (additionalFields.find(field => !field.isOptional &&
            !isValidCheck(additionalFieldsValues.find(fieldValue => field.id === fieldValue.fieldId)!.value))) {
            console.log(additionalFieldsValues)
            setIsAdditionalFieldsValid(false);
        }
        else {
            setIsAdditionalFieldsValid(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [additionalFieldsValues])

    useEffect(() => {
        setIsEventTitleValid(isValidCheck(eventTitle));
    }, [eventTitle])


    useEffect(() => {
        setIsPrticipantCountValid(isValidCheck(participantCount));
    }, [participantCount])


    useEffect(() => {
        const isValid = selectedTimes.length === 0 ? false : true;
        setIsSelectedTimesValid(isValid);
    }, [selectedTimes])

    function bookAuditory(lessonTimes: LessonTime[]) {
        const auditoryID = props.auditoryID;
        const params: ParamBookAuditoryDTO = {
            selectedDate, participantCount, selectedTimes,
            eventTitle, auditoryID, lessonTimes, additionalFields: additionalFieldsValues
        }

        dispatch(fetchBookAuditory(params))
            .then(() => { dispatch(scheduleSlice.actions.setIsBookingModalShown(false)) })
    }

    function toggleTimeHandle(time: LessonTime) {
        dispatch(toggleTime(time));
    }

    function isTimeSelected(scheduleItem: ScheduleColumn, lessonTime: LessonTime) {
        if (selectedDate !== scheduleItem.date) {
            return false
        }
        return selectedTimes.map(time => time.starts).includes(lessonTime.starts);
    }

    function inputParticipantCountHandle(value: number) {
        dispatch(setParticipantCount(value));
    }

    let lessonTimes: LessonTime[] = [];
    if (props.schedule !== null) {
        lessonTimes = getLessonTimes(props.schedule);
    }

    const [currentKeyTab, setCurrentKeyTab] = useState(0);
    const items = props.schedule.map((scheduleColumn, index) => {
        const isDisabled = moment().tz("Asia/Novosibirsk").startOf('day') > moment(scheduleColumn.date)
        return {
            label: moment(scheduleColumn.date).format("dd, D MMM"),
            key: index,
            disabled: isDisabled,
            children:
                isDisabled ?
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'center',
                            padding: '16px'
                        }}
                    >
                        <Typography.Title level={3}>Вы не можете забронировать аудиторию на предыдущую дату</Typography.Title>
                    </div>
                    :
                    lessonTimes.map(lessonTime => {

                        if (scheduleColumn.lessons.filter((lesson: { starts: number; type: string; }) => (lesson.starts === lessonTime.starts
                            && lesson.type !== "EMPTY")).length === 0) {
                            return <span
                                className={"time-pill" + (isTimeSelected(scheduleColumn, lessonTime) ? " selected" : "")}
                                key={lessonTime.starts}
                                onClick={() => {
                                    dispatch(setSelectedDate(scheduleColumn.date));
                                    toggleTimeHandle(lessonTime);
                                }}>
                                {moment.unix(lessonTime.starts).format("H:mm")} – {moment.unix(lessonTime.ends).format("H:mm")}
                            </span>
                        }
                        return null
                    })
        }
    })

    return (
        <Spin size={"large"} spinning={isLoading}>
            <Tabs
                items={items as any[]}
                onChange={(currentKey) => {
                    setCurrentKeyTab(parseInt(currentKey))
                    dispatch(tabIsChanged({ selectedDate: '', selectedTimes: [] }))
                }} />

            <div style={{ display: "flex", flexDirection: "column" }}>

                <strong>Название мероприятия <span style={{ color: 'red' }}> *</span></strong>
                <Input
                    style={{ marginBottom: 16 }}
                    maxLength={150}
                    disabled={items[currentKeyTab].disabled}
                    onChange={event => {
                        dispatch(setEventTitle(event.target.value));
                    }} />

                <strong>Количество участников <span style={{ color: 'red' }}> *</span></strong>
                <InputNumber

                    min={1}
                    style={{ width: "100%", marginBottom: 16 }}
                    disabled={items[currentKeyTab].disabled}
                    onChange={(value) => { inputParticipantCountHandle(value as number) }}
                />

                {
                    additionalFields.length > 0 ?
                        <>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}>
                                {
                                    additionalFields?.map(field => {
                                        return (
                                            <>
                                                <strong>
                                                    {field.title}
                                                    {!field.isOptional && <span style={{ color: 'red' }}> *</span>}
                                                </strong>
                                                <Input
                                                    style={{ marginTop: '4px', marginBottom: '8px' }}
                                                    disabled={items[currentKeyTab].disabled}
                                                    onChange={(e) =>
                                                        dispatch(setAdditionalFieldsValues({ fieldId: field.id, value: e.currentTarget.value }))
                                                    }
                                                />
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </>
                        : <></>
                }

                <Button type="primary"
                    style={{ marginTop: 16 }}
                    disabled={!(isEventTitleValid && isPrticipantCountValid && isSelectedTimesValid && isAdditionalFieldsValid && !items[currentKeyTab].disabled)}
                    onClick={() => bookAuditory(lessonTimes)}>Забронировать</Button>
            </div>
        </Spin>
    );
}

export default BookAuditory;